/**
 * Animated Form Labels
 * 
 * CSS class must be added in GForms admin on each field that will need to animate,
 * as well as a class of "animate-labels" on the form itself.
 */
var animateForm = document.querySelectorAll( '.animate-labels' );

if ( animateForm ) {
	var inputs = document.querySelectorAll( '.animate-labels input' );

	for ( var i = 0; inputs.length > i; i++ ) {
		var input = inputs[i];
		var value = input.value;

		if (value) {
			input.parentElement.classList.add( 'active' );
		}
		
		input.addEventListener( 'focus', function() {
			if ( this.parentElement.classList.contains( 'ginput_container' ) ) {
				this.parentElement.parentElement.classList.add( 'active' );
			} else {
				this.parentElement.classList.add( 'active' );
			}
		});

		input.addEventListener( 'blur', function() {
			var $this = this;
			
			setTimeout(function() {
				if ( $this.value == '' ) {
					$this.parentElement.parentElement.classList.remove( 'active' );
					$this.parentElement.classList.remove( 'active' );
				}
			}, 100);
		});
	}
}

jQuery(document).bind('gform_post_render', function(){
	var inputs = document.querySelectorAll( '.animate-labels input' );

	for ( var i = 0; inputs.length > i; i++ ) {
		var input = inputs[i];
		var value = input.value;

		if (value) {
			if ( input.parentElement.classList.contains( 'ginput_container' ) ) {
				input.parentElement.parentElement.classList.add( 'active' );
			} else {
				input.parentElement.classList.add( 'active' );
			}
		}
	}
});